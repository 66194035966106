export const domains = [

  //     domain: 'домен сайта', если домен на кириллице, то домен в punycode
  //     api: 'используется в компоненте dealer для изменения стандартной апи на нужную'
  //     siteId: 'site id',
  //     isIndexing: 'Индексирование / robots / sitemap',
  // cityTimezoneUTC: 'Временная зона города' по UTC
  // параметры для показа определенных блоков
  //     isShowingReviews: 'Показывать отзывы, если есть у нужных салонов',
  {
    domain: 'avalon-cars.ru',
    api: 'api.avalon-cars.ru',
    siteId: 459,
    siteIdNew: 137,
    isIndexing: true,
    options: {
      cityTimezoneUTC: 3,
      city: {
        rod: 'Санкт-Петербурга',
        dat: 'Санкт-Петербурге',
        pred: 'Санкт-Петербурге',
      },
      isShowingReviews: true,
    },
  },
  {
    domain: 'avalon-spb.ru',
    api: 'api.avalon-spb.ru',
    siteId: 511,
    siteIdNew: 137,
    isIndexing: true,
    options: {
      cityTimezoneUTC: 3,
      city: {
        rod: 'Санкт-Петербурга',
        dat: 'Санкт-Петербурге',
        pred: 'Санкт-Петербурге',
      },
      isShowingReviews: true,
    },
  },
  {
    domain: 'avalon-spbcars.ru',
    api: 'api.avalon-spbcars.ru',
    siteId: 512,
    siteIdNew: 137,
    isIndexing: true,
    options: {
      cityTimezoneUTC: 3,
      city: {
        rod: 'Санкт-Петербурга',
        dat: 'Санкт-Петербурге',
        pred: 'Санкт-Петербурге',
      },
      isShowingReviews: true,
    },
  },
  {
    domain: 'avalon-avto.ru',
    api: 'api.avalon-avto.ru',
    siteId: 519,
    siteIdNew: 137,
    isIndexing: true,
    options: {
      cityTimezoneUTC: 3,
      city: {
        rod: 'Санкт-Петербурга',
        dat: 'Санкт-Петербурге',
        pred: 'Санкт-Петербурге',
      },
      isShowingReviews: true,
    },
  },
];
export const devDomain = {
  domain: 'avalon-cars.ru',
  api: 'api.avalon-cars.ru',
  siteId: 459,
  siteIdNew: 137,
  isIndexing: true,
  options: {
    cityTimezoneUTC: 3,
    city: {
      rod: 'Санкт-Петербурга',
      dat: 'Санкт-Петербурге',
      pred: 'Санкт-Петербурге',
    },
    isShowingReviews: true,
  },
};

export function findDomain(host: string) {
  return domains.find((value) => value.domain === host) || devDomain;
}
